import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Img from "gatsby-image"

import "./../../styles/products.css"
import Slider, { Range } from "rc-slider"
import "rc-slider/assets/index.css"

//import components
import GetStartedBanner from "../../components/get-started-footer"

import GlobalCoverageSection from "../../components/global-coverage-section/global-coverage"
import MostPopularIntegrations from "../../components/most-popular-integration-section/most-popular-integrations"
import TrustedByCompanies from "../../components/companies-trusted"

const HeadingSection = ({ data }) => {
  const [productName, setproductName] = useState("Website monitoring")
  const [title, settitle] = useState("Reliable uptime & website monitoring service")
  // const [description, setdescription] = useState("Easy-to-use and accurate real-time monitoring of your websites and APIs to keep you up to date with performance. In the event of an outage, we notify you immediately via email, Slack, Webhooks, SMS, Telegram, or Discord")
  const [description, setdescription] = useState(
    "Automate your website monitoring and never worry again. We'll notify you immediately whenever there's an outage. "
  )
  const [getStartedButton, setgetStartedButton] = useState({
    name: "Get started for free",
    link: "https://app.odown.io/signup",
  })

  return (
    <div className="u-features api-monitoring">
      <div className="container">
        <div className="hero-col hero--text">
          <div className="hero-text__wrapper">
            <h1 className="u-features__heading-title white-text-color">
              {title}
            </h1>
            <p className="u-features__heading-desc white-text-color">
              {description}
            </p>
            <div className="u-features__heading-btns">
              {/* <a className="btn btn-primary" href={getStartedButton.link}></a> */}
              <OutboundLink
                className="btn btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blanc"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
        </div>
        <div className="hero-col hero--image">
          <div className="image-cover">
            <div className="image-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.headerImage.childImageSharp.fluid}
                alt={productName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const KeepAnEyeOnYourWebsite = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">website monitoring</span>
            <h2 className="u-main-feature_tc-title">
              Don't care about your website uptime
            </h2>
            <p className="u-main-feature_tc-desc">
              Hosting an online business on a platform that is down is the same
              as not having a website; even a short outage can become a
              long-term problem with your users and reputation. We're here to
              make sure you don't go through the pain of losing customers due to
              website downtime.
            </p>
            <p className="u-main-feature_tc-desc small">
              With our website monitoring service, we will keep an eye on your
              entire network and notify you as soon as a problem occurs. That
              way, you can fix it before your customers are even aware of the
              disruption.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.FeatureCustomHttpRequest.childImageSharp.fluid}
                alt="Website monitoring - Don't care about your website uptime"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const HistoricalUptimeMonitoringReports = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Result report</span>
            <h2 className="u-main-feature_tc-title">
              Long website monitoring log
            </h2>
            <p className="u-main-feature_tc-desc">
              Our result log lets you keep track of your website outages for up
              to a year. you can easily see historical results, compare them to
              your real-time website uptime reports, and see if your website has
              improved.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.Image_LongUptimeMonitoringLog.childImageSharp.fluid}
                alt="Website monitoring - Long website monitoring log"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const CustomCheckRate = ({ data }) => {
  const [ratesName, setratesName] = useState({
    11.11: "1min",
    22.22: "5min",
    33.33: "10min",
    44.44: "15min",
    55.55: "30min",
    66.66: "1hour",
    77.77: "6hour",
    88.88: "12hour",
    99.99: "24hour",
  })
  const [ratesDelay, setratesDelay] = useState({
    11.11: 1,
    22.22: 5,
    33.33: 10,
    44.44: 15,
    55.55: 30,
    66.66: 60,
    77.77: 360,
    88.88: 720,
    99.99: 1440,
  })

  const [dailychecks, setdailychecks] = useState(1440)
  const [monthlychecks, setmonthlychecks] = useState(1440 * 31)

  useEffect(() => {}, [])

  return (
    <section className="u-main-feature left-text custom-uptime-checking-rate-preview">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Check rates</span>
            <h2 className="u-main-feature_tc-title">
              Customize your website uptime checking rates
            </h2>
            <p className="u-main-feature_tc-desc">
              Stay up-to-date on your website at all times with our website
              monitoring service. Use our constant check rates that run every 1
              minute, 5 minutes, 10 minutes, 15 minutes, and up to 24 hours.
              Stop worrying about outages and know that you'll get an instant
              alert from us if your website goes down.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <div className="selectedRate-preview">
                <ul>
                  <li>
                    <label htmlFor="">Daily checking : </label>
                    <span>{dailychecks}</span> runs
                  </li>
                  <li>
                    <label htmlFor="">Monthly checking : </label>
                    <span>{monthlychecks}</span> runs
                  </li>
                </ul>
              </div>
              <Slider
                min={11.11}
                defaultValue={11.11}
                marks={ratesName}
                step={null}
                included={false}
                onChange={e => {
                  let dailyChecks = 1440 / ratesDelay[e]
                  setdailychecks(dailyChecks)
                  setmonthlychecks(dailyChecks * 31)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CustomHttpResquestSection = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Endpoint request method
            </span>
            <h2 className="u-main-feature_tc-title">Custom HTTP request</h2>
            <p className="u-main-feature_tc-desc">
              We let you choose your adventure with a rich set of customization
              options: You decide the HTTP method, URL, request parameters,
              headers, and body. Add credentials or a media token for
              authentication.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.FeatureCustomHttpRequest.childImageSharp.fluid}
                alt="Website monitoring - Custom HTTP request"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ReportApiMonitoringSection = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Rich data</span>
            <h2 className="u-main-feature_tc-title">
              Everything you need, visualized
            </h2>
            <p className="u-main-feature_tc-desc">
              Monitor your sites with simple and beautiful charts. Measure your
              uptime, response time, and more every minute
              <br />
              <br />
              We also record the response time for each request, down to TCP,
              DNS, and first byte times.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.FeatureBreakDown.childImageSharp.fluid}
                alt="Website monitoring - Everything you need, visualized"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const AlertingSection = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Unlimited notifications
            </span>
            <h2 className="u-main-feature_tc-title">
              Alerting for any situation
            </h2>
            <p className="u-main-feature_tc-desc">
              When a monitor fails, you and your team need timely and actionable
              insights to address any downtime. Add Slack, SMS, or email.
              <br />
              <br />
              Our alerts give you the correct information whatever alerting
              medium you choose. Need more control? Webhooks give you full
              access to the JSON data we use for alerting.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.FeatureAlerting.childImageSharp.fluid}
                alt="Website monitoring - Alerting for any situation"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const API_Monitoring = ({ data }) => (
  <Layout
    currentPage={`product-api_monitoring`}
    navParams={{ textColor: "white" }}
  >
    <SEO
      title="Monitor Website Uptime & API Performance with Odown"
      description="Stay on top of your website and API performance with our easy-to-use Website Monitoring Service. Get real-time alerts on outages and issues so you can respond quickly. Never miss a beat with our comprehensive monitoring solution."
      pathname={`/product/website-monitoring/`}
      // meta={[
      // 	{
      // 		name : `twitter:image:src`,
      // 		content :  data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},
      // 	{
      // 		name : `og:image`,
      // 		content : data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},

      // ]}
      image={{
        src: "/general-image-og--website-monitoring.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection data={data} />
    <TrustedByCompanies direction="left" />
    <KeepAnEyeOnYourWebsite data={data} />
    <HistoricalUptimeMonitoringReports data={data} />
    <CustomCheckRate data={data} />
    <CustomHttpResquestSection data={data} />
    <ReportApiMonitoringSection data={data} />
    <AlertingSection data={data} />
    <MostPopularIntegrations data={data} />
    <GlobalCoverageSection data={data} />
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    headerImage: file(
      relativePath: { eq: "product__api-monitoring__header2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    FeatureCustomHttpRequest: file(
      relativePath: {
        eq: "product__api-monitoring__option__custom-http-request.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    FeatureBreakDown: file(
      relativePath: { eq: "product__api-monitoring__option__break-down.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    FeatureAlerting: file(
      relativePath: { eq: "product__api-monitoring__option__alerting.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    FeatureGlobalCoverage: file(
      relativePath: {
        eq: "product__api-monitoring__option__global-coverage.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    Image_LongUptimeMonitoringLog: file(
      relativePath: {
        eq: "product__api-monitoring__section_long-uptime-monitoring-log.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default API_Monitoring
